<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver" class="w-100 mr-lg-1">
      <b-form @submit.prevent="handleSubmit(handleCreateItem)">
        <div class="float-md-right mt-md-n3 mb-1 mb-md-0 ml-75 mr-md-2">
          <div class="d-flex align-items-center">
            <v-select
              v-model="selectedLanguage"
              :options="locales"
              :searchable="false"
              :clearable="false"
              label="text"
              :reduce="language => language.value"
              style="width: 160px;"
            />
            <b-button
              size="sm"
              class="float-right ml-1 zindex-1"
              variant="primary"
              type="submit"
            >
              {{ $t('form.actions.save') }}
            </b-button>
            <b-button
              v-if="isNewPage"
              size="sm"
              class="float-right ml-1 zindex-1"
              variant="secondary"
              @click="closeModal"
            >
              {{ $t('form-create-item.cancel') }}
            </b-button>
          </div>
        </div>

        <div class="blog-container">
          <b-spinner
            v-if="isLoading"
            class="center-x my-3"
            small
            variant="primary"
            type="grow"
          />

          <!-- content -->
          <div class="blog-detail-wrapper">
            <b-row class="justify-content-md-center">
              <!-- blogs -->
              <b-col cols="12">
                <b-card class="p-md-2 pb-lg-3">
                  <div>
                    <validation-provider #default="validationContext" name=" " rules="required">
                      <b-form-group class="mb-25">
                        <b-form-input
                          v-model="form.name[selectedLanguage]"
                          class="edit-title"
                          required
                          rules="required"
                          type="text"
                          :placeholder="$t('form.placeholder')"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback id="dataToSend-surname">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-media no-body class="pt-0">
                      <!-- Avatar -->
                      <!-- Owner and date-->
                      <b-media-body class="mb-2">
                        <small class="text-muted mr-50">{{ $t('articles.created-by') }}</small>
                        <small>
                          <b-link class="text-body">
                            {{ loggedMember.name }} {{ loggedMember.surname }}
                          </b-link>
                        </small>
                        <span class="text-muted ml-75 mr-50">|</span>
                        <small class="text-muted">{{ timestampToTime(date) }}</small>
                      </b-media-body>
                    </b-media>
                  </div>

                  <!-- Description -->
                  <quill-editor v-model="form.body[selectedLanguage]" :options="options" />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import moment from 'moment';
import { getImageResource } from '@/@core/utils/image-utils';
import { quillEditor } from 'vue-quill-editor';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';

export default {
  name: 'ArticleCreate',
  components: {
    quillEditor,
    FormTranslationTable,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isNewPage: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    mainArticle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      imageSrc: null,
      newComment: '',
      comments: [],
      form: { name: {}, body: {} },
      itemImage: null,
      editedItem: {},
      lastLoadedPage: 1,
      selectedLanguage: this.currentLocale,
      required,
      options: {
        placeholder: this.$t('form.placeholder'),
      },
    };
  },
  computed: {
    date() {
      return +new Date();
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    availableLanguages() {
      return this.$store.getters.currentCollective?.availableLanguages;
    },
    locales() {
      const { locales } = this.$store.getters;
      const communityLanguages = locales.filter((language) => this.availableLanguages.includes(language.value));
      return communityLanguages;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return Placeholder;
    },
    item() {
      if (this.mainArticle) {
        return this.$store.getters.fragmentedItem('articles', this.id, this.mainArticle.key);
      }
      if (this.$store.getters.articles.articles) {
        return this.$store.getters.fragmentedItem('articles', this.id, 'articles');
      }
      return {};
    },
    classifiersData() {
      if (this.$store.getters.classifiers.blog) {
        return this.$store.getters.classifiers.blog.unpaginated;
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    // await this.fetchItem();
    this.updateBreadcrumbs();
    this.selectedLanguage = this.currentLocale;
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.itemImage = files[0];
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async scrollBottom() {
      const element = document.getElementById('commentForm');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    // TODO: Remove this workaround. The BE should always return objects
    getText(value) {
      if (!value) return;
      if (typeof value === 'object') return value[this.currentLocale] || Object.values(value)[0];
      if (value[0] === '<') return value;
      const object = JSON.parse(value);
      return object[this.currentLocale] || Object.values(object)[0];
    },
    async handleCreateItem() {
      try {
        this.loading = true;
        const parentKey = this.isNewPage ? this.id : '';
        const result = await this.$store.dispatch('createItem', {
          item: {
            itemType: 'articles',
            storedKey: this.isNewPage ? this.id : 'articles',
            orderByDate: this.isNewPage ? 1 : -1,
            requestConfig: {
              name: this.form.name,
              body: this.form.body,
              parentKey,
            },
          },
          file: this.itemImage,
        });
        this.updateBreadcrumbs();
        this.loading = false;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('articles.message.saved-success'),
            variant: 'success',
          },
        });
        this.form = { name: {}, body: {} };
        if (this.isNewPage) {
          this.$emit('closeModal');
          return;
        }

        this.$router.push({ name: 'article', params: { id: result.data.key } });
      } catch (e) {
        console.log(e);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    // TODO: move to utils
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'articles.breadcrumb-text.articles-list',
          to: { name: 'articles' },
        },
        {
          text: 'articles.create-title',
          active: true,
        },
      ];
      if (this.isNewPage) {
        const breadcrumbPiece = {
          text:
            this.item?.name[this.currentLocale]
            || Object.values(this.item?.name)[0]
            || 'articles.breadcrumb-text.article-details',
          active: true,
        };
        breadcrumbs.splice(1, 0, breadcrumbPiece);
        if (this.mainArticle) {
          const breadcrumbPiece = {
            text:
            this.mainArticle?.name[this.currentLocale]
            || Object.values(this.mainArticle?.name)[0]
            || 'articles.breadcrumb-text.article-details',
            active: true,
          };
          breadcrumbs.splice(1, 0, breadcrumbPiece);
        }
      }
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    closeModal() {
      this.form = { name: {}, body: {} };
      this.$emit('closeModal');
    },
  },
};
</script>
<style>
.blog-container {
  max-width: auto;
  margin: 0 auto;
}

.imagen {
  max-height: 500px !important;
  object-fit: cover !important;
  overflow: hidden;
}

.edit-title {
  font-size: 1.5rem;
  color: #5e5873;
  font-weight: 500;
}
.ql-container .ql-editor span {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.ql-container .ql-editor p {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: black;
}
.form-file-custom {
  position: absolute;
  top: 8px;
  right: 2%;
}
.card-imgage-edit {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0.85rem;
    border-radius: 50%;
    background: rgba(0,0,0,0.2);
    height: 45px;
    width: 45px;
}
.card-imgage-edit svg {
  color: #f0f0f0 !important;
}
</style>
